import { Component } from "react";

class PreventBackNavigation extends Component {
  componentDidMount() {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", this.handleBackButton);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleBackButton);
  }

  handleBackButton = () => {
    window.history.pushState(null, "", window.location.href);
  };

  render() {
    return null;
  }
}

export default PreventBackNavigation;
