import React from "react";

import {
  // Customizable Area Start
  Box, Card, CardContent, Grid, Typography, styled,BoxProps
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CategoriessubcategoriesController, {
  Props,
} from "./CategoriessubcategoriesController";
import SideNavbar from "../../../components/src/SideNavbar.web";
import { NoStudyFoundLogo } from "./assets";
const SelectTheStudy: { [key: string]: string } = {
  en: "Select the study",
  fr: "Sélectionnez l'étude",
  es: "Seleccione el estudio",
  de: "Wählen Sie die Studie aus",
};

const studyNumber: { [key: string]: string } = {
  en: "Study Number",
  fr: "Numéro d'étude",
  es: "Número de estudio",
  de: "Studiennummer",
};
import PreventBackNavigation from "../../../components/src/PreventBackNavigation.web"

// Customizable Area End

export default class Categoriessubcategories extends CategoriessubcategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const BoxView = Box as React.FC<BoxProps>
    // Customizable Area End
    return (
      // Customizable Area Start
      <React.Fragment>
        <SideNavbar navigation={this.props.navigation} id={this.props.id} showStudy={this.state.showStudy}
        handleLanguageSwitch={this.handleLanguageSwitch}
        language={this.state.language}
        />
        <CardStyle>
          {this.state.study && this.state.study.length > 0 ? (
            <BoxView className="wrapper">
              <Typography className="selectText">{SelectTheStudy[this.state.language] || SelectTheStudy['en']}</Typography>
              <BoxView className="main">
                <Grid container item xs={9} className="gridBox">
                  {this.state.study?.map((std: any) => {
                    return (
                      <>
                        <Card className="card"  key={std.attributes.study_name}>
                          <CardContent onClick={() => this.handleSelectedStudyCate(std)} id="card-content">
                            <Typography className="studyname">{std.attributes.study_name}</Typography>
                            <Typography className="studyNum">{studyNumber[this.state.language]}: {std.attributes.study_number}</Typography>
                          </CardContent>
                        </Card>
                      </>
                    )
                  })}
                </Grid>
              </BoxView>
            </BoxView>) : (<Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" style={{ height: '80vh' }} >
              <BoxView>
                <img src={NoStudyFoundLogo} alt="NoStudyFoundLogoName" />
              </BoxView>
              <BoxView>
                <Typography className="noStudyText">No Study Found</Typography>
              </BoxView>
            </Grid>)}
        </CardStyle>
        <PreventBackNavigation/>
      </React.Fragment>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CardStyle = styled((Box as React.FC<BoxProps>))({
  "& .card": {
    cursor: 'pointer',
    width: '100%',
    maxWidth: "320px",
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: "20px",
    background: "#FFF",
    padding: '20px 10px'
  },
  "& .gridBox": {
    paddingTop: '50px',
    padding: '20px',
    gap: '32px',
    display: "flex",
    justifyContent: "center"
  },
  "& .main": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center"
  },
  "& .selectText": {
    color: "#1F3650",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 600,

  },
  "& .wrapper": {
    paddingTop: '100px',
  },
  "& .studyname": {
    color: "#292929",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 600
  },
  "& .studyNum": {
    paddingTop: '28px',
    color: "#292929",
    textAlign: "center",
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600
  },
  "& .noStudyText": {
    paddingTop: '34px',
    color: "#000",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 400
  }
});
// Customizable Area End
