import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { setStorageData } from "../../../framework/src/Utilities";
import { QuickBlox } from "quickblox/quickblox";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  language: string;
  studyNameStore:string,
  token: string;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  study:[{
    id: string;
    type: string;
    attributes: {
        study_name: string;
        study_number: string;
        study_description: string;
        account_id: number;
        chat: boolean;
        community: boolean;
        gamification: boolean;
        archived: boolean;
        published: boolean;
        milestone_managements: {
            data: {
              id: string;
              type: string;
              attributes: {
                  id: number;
                  start_date: string;
                  end_date: string;
                  milestone_description: string;
                  name: string;
              };
          }
        };
        faq_managements: {
            data: {
              id: string;
              type: string;
              attributes: {
                  id: number;
                  question: string;
                  answer: string;
                  images:  {
                    url: string;
                };
                  videos: {
                    url: string;
                }
              };
          }
        };
    };
}] | "",
  showStudy:boolean;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoriesApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  // Customizable Area Start
  getStudyDataApiCallId: any;
  QB = new QuickBlox
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      studyNameStore: "",
      token: "",
      categoriesArray: [],
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      study: "",
      showStudy:true,
      language: localStorage.getItem('language') || 'en'
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    this.getStudyDataIndexDetail();
    // Customizable Area End
  }
  
  // Customizable Area Start

  connectToUser = (error:any, result:any) => {
  }

  createSession1 = async (error: any, result: any) => {
    if(result !== null){
      console.log('resultwrt',result);
      localStorage.setItem("userId",result?.user_id)
      localStorage.setItem("chatToken",result?.token)
      const userCredentials = {
        userId: result?.user_id,
        password: result?.token,}
        this.QB.chat?.connect(userCredentials, this.connectToUser);
      }
  }

  getConnectionQuickBlox = async()=>{
    
    const APPLICATION_ID_1 = 103325;
    const AUTH_KEY_1 = "ak_DgCfDaNhYzuF3NU";
    const AUTH_SECRET_1 = "as_bAPcA9mH9vLgZvJ";
    const ACCOUNT_KEY_1 = "ack_djxvQgxHLG1B3PA4iQyP";
    const CONFIG_1 = {
      // other settings
      webrtc: {
        disconnectTimeInterval: 86400,
      }
      // other settings
    };

    this.QB.init(APPLICATION_ID_1, AUTH_KEY_1, AUTH_SECRET_1, ACCOUNT_KEY_1,CONFIG_1);

    const userUniqnueId:any = localStorage.getItem("userUniqnueId");
    const userPass1 = 'Password@123'
    const paramsLogin1 = {login:userUniqnueId, password:userPass1}
    
    this.QB.createSession(paramsLogin1, this.createSession1)

  }
 
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token }, () => {
        this.getCategories();
      });

    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null && responseJson !== undefined) {
        const apiHandlers = {
          [this.getStudyDataApiCallId]: () => {
            if (responseJson.studies.data.length > 0) {
              this.setState({
                study: responseJson.studies.data,
              })
            }
           }
          };

          const apiHandler = apiHandlers[apiRequestCallId];

        if (apiHandler) {
        apiHandler();
        }
      }
      runEngine.debugLog("API Message Recived", message);
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    return (array + index)
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus
    });
  };
  expand = (id: string) => {
    let array = this.state.categoriesArray;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i].expand = !array[i].expand;
      }
    }
    this.setState({ categoriesArray: array });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addCategory = () => {
    if (this.isStringNullOrBlank(this.state.category)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      let data = {
        categories: [{ name: this.state.category }]
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.categoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  addSubCategory = () => {
    if (this.isStringNullOrBlank(this.state.subCategory)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (this.state.selectedCategoryID.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorCategory);
      return false;
    } else {
      let data = {
        sub_category: {
          name: this.state.subCategory
        },
        parent_categories: this.state.selectedCategoryID
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addSubCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subCategoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteSubCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSubCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCategories = () => {

    if (!this.state.token) {
      return;
    }
    
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStudyDataIndexDetail = () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudiesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    this.getStudyDataApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSelectedStudyCate = (std:any) => {
    setStorageData("studyName", JSON.stringify(std));
    setStorageData("studyId", std.id);   
    this.getConnectionQuickBlox()
    this.props.navigation.navigate("Dashboard", { selectedStudy: std })
  }

  handleLanguageSwitch = (language: string) => {
    this.setState({ language: language })
    localStorage.setItem('language', language)
  }
  // Customizable Area End
}
